import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/verandas.module.css"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

const Verandas = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "verandas.json" }) {
        childDataJson {
          verandas {
            collection
            nom
            image {
              childImageSharp {
                fixed(width: 350) {
                  ...GatsbyImageSharpFixed
                }
                id
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section id="portes" className="first w-75-ns center">
      <h1 className={styles.titre}>
        <FormattedMessage id="verandas.title" />
      </h1>
      <p className="tc">
        Retrouvez notre large sélection de vitraux pour vérandas, ainsi que nos{" "}
        <Link to="/porte-entrees">vitrails porte d'entrée.</Link>
      </p>
      <div id="articles" className="flex flex-wrap justify-around mv6">
        {data.file.childDataJson.verandas.map(veranda => (
          <div
            key={veranda.image.childImageSharp.id}
            className="w-100 w-50-l mv3"
          >
            <Img
              fixed={veranda.image.childImageSharp.fixed}
              style={{ display: "block", margin: "0 auto" }}
              alt={veranda.nom}
            />
            <p className={styles.contenu} style={{ margin: "10px auto" }}>
              {veranda.nom} <br />
              {veranda.collection}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Verandas
